import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Link } from 'react-router-dom';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loading1 from './components/Loading1';
import l1 from './assets/l1.png';
import l2 from './assets/l2.png';
import l3 from './assets/l3.png';
import l4 from './assets/l4.png';
// import PreloadImages from './components/PreloadImages';

const Home = React.lazy(() => import('./components/Route/Home'));
const Footer = React.lazy(() => import('./components/Home/Footer'));
const ITSolution = React.lazy(() => import('./components/Route/ITSolution'));
const AidedDispatch = React.lazy(() => import('./components/Route/AidedDispatch'));
const HealthSecreening = React.lazy(() => import('./components/Route/HealthSecreening'));
const Chatbot = React.lazy(() => import('./components/Chatbot/Chatbot'));


const Healthcare = React.lazy(() => import('./components/Route/Healthcare'));
const AboutUsRoute = React.lazy(() => import('./components/Route/AboutUsRoute'));
const AboutHeader = React.lazy(() => import('./components/AboutUs/AboutHeader'));
const ContactRoute = React.lazy(() => import('./components/Route/ContactRoute'));
const JoinUsRoute = React.lazy(() => import('./components/Route/JoinUsRoute'));
const PhysicianAssistantRoute = React.lazy(() => import('./components/HomeHealthcareServices/PhysicianAssistant/Route'));
const PhysiotherapyRoute = React.lazy(() => import('./components/HomeHealthcareServices/Physiotherapy/PhysioRoute'));
const HealthcareAttendentRoute = React.lazy(() => import('./components/HomeHealthcareServices/HealthcareAttendent/HealthcareRoute'));
const NursesRoute = React.lazy(() => import('./components/HomeHealthcareServices/Nurse/NurseRoute'));
const Respiratory = React.lazy(() => import('./components/HomeHealthcareServices/Respiratory/RespRoute'));
const MedicalEquipments = React.lazy(() => import('./components/HomeHealthcareServices/MedicalEquipments/MedicalRoute'));
const Xray = React.lazy(() => import('./components/HomeHealthcareServices/Xray/XrayRoute'));
const Pathology = React.lazy(() => import('./components/HomeHealthcareServices/Pathology/PathologyRoute'));
const FleetManagement = React.lazy(() => import('./components/Route/FleetRoute'));
const AssistedLiving = React.lazy(() => import('./components/Route/AssistedRoute'));
const SmartCity = React.lazy(() => import('./components/Route/SmartCityRoute'));
const PrivacyPolicyRoute = React.lazy(() => import('./components/Route/PrivacyPolicyRoute'));

function App() {
  useEffect(() => {
    const aosInterval = setInterval(() => {
      AOS.init();
    }, 1000);

    return () => clearInterval(aosInterval);
  }, []);

  const [loadingStep, setLoadingStep] = useState(1);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const loadingInterval = setInterval(() => {
      if (loadingStep < 5) {
        setLoadingStep(loadingStep + 1);
      } else {
        setLoadingComplete(true);
        clearInterval(loadingInterval);
      }
    }, 100);

    return () => clearInterval(loadingInterval);
  }, [loadingStep]);


  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, [location.pathname]);

  const openLinkedInLink = () => {
    window.open('https://www.linkedin.com/company/spero-healthcare-innovations-private-limited/');
  };
  const openFacebookLink = () => {
    window.open('https://www.facebook.com/sperohealthcareinnovations/');
  };
  const openInstagramLink = () => {
    window.open('https://www.instagram.com/sperohealthcare/?igsh=MWdxb3ZpOGRqMTh0ZA%3D%3D');
  };

  const openTwitterLink = () => {
    window.open('https://twitter.com/Spero2016/');
  };
//top scroll button

const [showButton, setShowButton] = useState(false);

useEffect(() => {
  function handleScroll() {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

  return (
    <div className="App">
      {loadingStep === 1 && <Loading1 text={l1} />}
      {loadingStep === 2 && <Loading1 text={l2} />}
      {loadingStep === 3 && <Loading1 text={l3} />}
      {loadingStep === 4 && <Loading1 text={l4} />}
      {loadingStep === 5 && (
        <>
          <AboutHeader />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/itsolution" element={<ITSolution />} />
              <Route path="/sperocad" element={<AidedDispatch />} />
              <Route path="/HealthScreening" element={<HealthSecreening />} />
              <Route path="/homehealthcare" element={<Healthcare />} />
              <Route path="/FleetManagement" element={<FleetManagement />} />
              <Route path="/AssistedLiving" element={<AssistedLiving />} />
              <Route path="/SmartCity" element={<SmartCity />} />
              <Route path="/aboutus" element={<AboutUsRoute />} />
              <Route path="/contact" element={<ContactRoute />} />
              <Route path="/careers" element={<JoinUsRoute />} />
              <Route path="/Physicianassistant" element={<PhysicianAssistantRoute a={0} />} />
              <Route path="/Physiotherapy" element={<PhysiotherapyRoute a={1} />} />
              <Route path="/HealthcareAttendent" element={<HealthcareAttendentRoute a={2} />} />
              <Route path="/Nurses" element={<NursesRoute a={3} />} />
              <Route path="/Respiratory" element={<Respiratory a={4} />} />
              <Route path="/MedicalEquipments" element={<MedicalEquipments a={5} />} />
              <Route path="/Xray" element={<Xray a={6} />} />
              <Route path="/Pathology" element={<Pathology a={7} />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicyRoute/>} />
              <Route path="/chatbot" element={<Chatbot/>} />

            </Routes>
            <Footer />
          </Suspense>
        </>)}

      <div className="sticky-icon">
        <Link onClick={openFacebookLink} className="Facebook"><i className="fab fa-facebook-f"> </i></Link>
        <Link onClick={openInstagramLink} className="Instagram"><i className="fab fa-instagram" /></Link>
        <Link onClick={openLinkedInLink} className="linkedin1"><i className="fab fa-linkedin"> </i></Link>
        {/* <a href="/#" className="Youtube"><i className="fab fa-youtube" /></a> */}
        <Link onClick={openTwitterLink} className="Twitter"><i className="fab fa-twitter"> </i></Link>

        <span><Chatbot/></span> 
      </div>
      <span><Chatbot/></span> 

      <Link 
      id="button" 
      className={showButton ? 'show' : ''}
      onClick={scrollToTop}
    >
    </Link>

    </div>
  );
}

export default App;
