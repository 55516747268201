import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AOS from 'aos';
import { BrowserRouter as Router } from 'react-router-dom';
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'hover.css/css/hover-min.css';
import 'slick-carousel';
const root = ReactDOM.createRoot(document.getElementById('root'));

// document.addEventListener('contextmenu', function(event) {
//   event.preventDefault();
// });

root.render(
  <Router>
    <App />
  </Router>
);

AOS.init();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
